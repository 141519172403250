const colors = [
    "#528DE2",
    "#EFFF59",
    "#D40016",
    "#A000D9",
    "#F49333",
    "#F500A5",
    "#5300D7",
    "#5DE3E9",
    "#BD6BE7",
    "#000000",
    "#800000",
    "#FF0000",
    "#FF00FF",
    "#008080",
    "#7fffd4",
    "#8a2be2",
    "#deb887",
    "#5f9ea0",
    "#d2691e",
    "#dc143c",
    "#b8860b",
    "#a9a9a9",
    "#ff8c00",
    "#483d8b",
    "#4b0082",
    "#f0e68c",
    "#191970",
    "#d2b48c",
    "#ee82ee",
    "#BA68C8",
    "#7B1FA2",
    "#E040FB",
    "#7986CB",
    "#303F9F",
    "#536DFE",
    "#64FFDA",
    "#FFF176",
    "#FFD54F",
    "#FFA000",
    "#FFD740",
    "#FF8A65",
    "#E64A19",
    "#FF6E40",
    "#A1887F",
    "#5D4037",
    "#BDBDBD",
    "#616161",
    "#90A4AE",
    "#455A64",
    "#CFD8DC",
    "#FF1744",
    "#FCE4EC",
    "#AD1457",
    "#6A1B9A",
];

export default colors;