<template>
	<div>
        <v-row class="mt-1">
            <v-col class="pt-0" cols="12" lg="6" >
                <bar-graph
                    ref="graph1"
                    title="Cuentas por pagar"
                    
                    :lastPage="lastPageCXP"
                    @makeRequest="updateCXPData"
                    :chartDataInput="cxpData"
                    :loading="loadingCXP"
                    :yAxisCurrency="false"
                    data-cy="graph-1"
                >
                    <template slot="filters">
                        <v-col>
                            <autocompleteMultipleForm
                                class="testtest"
                                label="Proveedores"
                                :valueInput="suppliersSelected"
                                :rule="[]"
                                :items="suppliers"
                                :loading="loadingSuppliers"
                                @valueChange="(v) => {suppliersSelected = v; setCXPData()}"
                                :selectAll="true"
                                data-cy="suppliers-input"
                            ></autocompleteMultipleForm>
                        </v-col>
                    </template>
                </bar-graph>
            </v-col>


            <!-- CXC -->
            <v-col class="pt-0" cols="12" lg="6" >
                <bar-graph
                    ref="graph2"
                    title="Cuentas por cobrar"
                    
                    :lastPage="lastPageCXC"
                    @makeRequest="updateCXCData"
                    :chartDataInput="cxcData"
                    :loading="loadingCXC"
                    :yAxisCurrency="false"
                    data-cy="graph-2"
                >
                    <template slot="filters">
                        <v-col>
                            <autocompleteMultipleForm
                                class="testtest"
                                label="Clientes"
                                :valueInput="clientsSelected"
                                :rule="[]"
                                :items="clients"
                                :loading="loadingClients"
                                @valueChange="(v) => {clientsSelected = v; setCXCData()}"
                                :selectAll="true"
                                data-cy="clients-input"
                            ></autocompleteMultipleForm>
                        </v-col>
                    </template>
                </bar-graph>
            </v-col>
            
        </v-row>
		
	</div>
</template>
<script>
// import Utils from '../../helpers/Utils';
import BarGraphVue from './BarGraph.vue';
import colors from "./Colors";

// import Utils from '../../helpers/Utils';
export default {
    components: {
        'bar-graph': BarGraphVue,
    },
    data(){
        return {
           



            loadingCXC:false,
            loadingCXP:false,

            clientsSelected:[],
            clients:[],
            loadingClients: false,
            lastPageCXC: 999,
            cxcRawData: [],
            cxcServerData: [
                {
                    page:0,
                    data:[]
                }
            ],

            suppliersSelected: [],
            suppliers:[],
            loadingSuppliers:false,
            lastPageCXP: 9999,
            cxpRawData:[],
            cxpServerData: [
                {
                    page:0,
                    data:[]
                }
            ],

            controlKey:1,
           

            chartData: {
                labels: this.labels,
                datasets: [
                    {
                        
                        backgroundColor: this.color,
                        data: this.values
                    }
                ]
            },
            cxpData:{
                labels:[
                    // 'service 1222dfsdf sdfsdfg', 'service 1', 'service 1', 'service 1', 'service 1', 'service 1', 'service 1', 'service 1', 'service 1', 'service 10',
                ],
                datasets:[
                    {
                        label: 'SALDO', //quotations
                        backgroundColor:colors,
                        data: []
                    }
                    
                   
                ]
            },

            cxcData:{
                labels:[
                    // 'service 1222dfsdf sdfsdfg', 'service 1', 'service 1', 'service 1', 'service 1', 'service 1', 'service 1', 'service 1', 'service 1', 'service 10',
                ],
                datasets:[
                    {
                        label: 'SALDO', //quotations
                        backgroundColor: colors,
                        data: []
                    }
                    
                   
                ]
            },
            
            
            
        }
    },

	mounted(){
        this.getDebtsToPay()
        this.getReceivableAccounts();
		// this.getSuppliers();
	},

    methods: {
        getSuppliers()
        {
            // this.loadingClients = true;
            // this.$api.supplier.getSelect() 
            //     .then((resp) => {
            //         this.suppliers = resp.data;
            //     })
            //     .catch((error) => {
            //         console.log(error);
            //     })
            //     .finally(() => {
            //         this.loadingClients = false;
            //     })
            // ;
            this.suppliers = this.cxpRawData.map((item) => {
                return {value: item.supplier_id, text: item.supplier_name}
            })
        },
        getDebtsToPay()
        {
            
            this.loadingCXP = true;
            this.clearCXPData();
            this.$api.report.debtsToPay()
                .then((resp) => {
                    this.cxpRawData = JSON.parse(JSON.stringify(resp.data));
                    
                    this.setCXPData();
                    this.getSuppliers();
                  
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    // this.loadingClients = false;
                    this.loadingCXP = false;
                })
            ;
        },
        setCXPData()
        {
            this.clearCXPData();
            this.cxpServerData = [
                {
                    page:0,
                    data:[]
                }
            ];
            //all

            if(this.suppliersSelected.length == 0){
               
                this.lastPageCXP = Math.ceil( this.cxpRawData.length / 10);
                for (let j = 0; j < this.lastPageCXP; j++) {
                    this.cxpServerData.push({page:(j+1), data:[]})                        
                }
                let count = 0;
                let pageCount = 1;
                this.cxpRawData.forEach((item)=> {
                    if(count <= 9){
                        // console.log(item, pageCount);
                        this.cxpServerData[pageCount].data.push(item)
                    }
                    else{
                        count = 0;
                        pageCount = pageCount + 1;
                        this.cxpServerData[pageCount].data.push(item)
                    }

                    count = count + 1;

                });
            }else{
               
                let cxpNewData = [];
              
                cxpNewData = this.cxpRawData.filter((itm) => this.suppliersSelected.includes(itm.supplier_id))

                this.lastPageCXP = Math.ceil( cxpNewData.length / 10);
                if(this.lastPageCXP == 0)
                    this.lastPageCXP = 1;
                for (let j = 0; j < this.lastPageCXP; j++) {
                    this.cxpServerData.push({page:(j+1), data:[]})                        
                }
                let count = 0;
                let pageCount = 1;
                cxpNewData.forEach((item)=> {
                    if(count <= 9){
                        this.cxpServerData[pageCount].data.push(item)
                    }
                    else{
                        count = 0;
                        pageCount = pageCount + 1;
                        this.cxpServerData[pageCount].data.push(item)
                    }
                    count = count + 1;
                });
            }
            this.$refs.graph1.resetPage();
            this.updateCXPData({page:1});
        },
        updateCXPData(data)
        {
            this.clearCXPData();
            this.cxpServerData[data.page].data.forEach(item => {
                this.cxpData.labels.push(item.supplier_name);
                this.cxpData.datasets[0].data.push(item.paid);
            });
            
        },
        
        clearCXPData()
        {
            
            this.cxpData.labels = [];
            this.cxpData.datasets[0].data = []
        },



        getClients()
        {
           
            this.clients = this.cxcRawData.map((item) => {
                return {value: item.client_id, text: item.client_name}
            })
        },
        getReceivableAccounts()
        {
            
            this.loadingCXC = true;
            this.clearCXCData();
            this.$api.report.receivableAccounts()
                .then((resp) => {
                    this.cxcRawData = JSON.parse(JSON.stringify(resp.data));
                    
                    this.setCXCData();
                    this.getClients();
                  
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    // this.loadingClients = false;
                    this.loadingCXC = false;
                })
            ;
        },
        setCXCData()
        {
            this.clearCXCData();
            this.cxcServerData = [
                {
                    page:0,
                    data:[]
                }
            ];
            //all

            if(this.clientsSelected.length == 0){
               
                this.lastPageCXC = Math.ceil( this.cxcRawData.length / 10);
                for (let j = 0; j < this.lastPageCXC; j++) {
                    this.cxcServerData.push({page:(j+1), data:[]})                        
                }
                let count = 0;
                let pageCount = 1;
                this.cxcRawData.forEach((item)=> {
                    if(count <= 9){
                        // console.log(item, pageCount);
                        this.cxcServerData[pageCount].data.push(item)
                    }
                    else{
                        count = 0;
                        pageCount = pageCount + 1;
                        this.cxcServerData[pageCount].data.push(item)
                    }

                    count = count + 1;

                });
            }else{
                let cxcNewData = [];
              
                cxcNewData = this.cxcRawData.filter((itm) => this.clientsSelected.includes(itm.client_id))

                this.lastPageCXC = Math.ceil( cxcNewData.length / 10);
                if(this.lastPageCXC == 0)
                    this.lastPageCXC = 1;
                for (let j = 0; j < this.lastPageCXC; j++) {
                    this.cxcServerData.push({page:(j+1), data:[]})                        
                }
                let count = 0;
                let pageCount = 1;
                cxcNewData.forEach((item)=> {
                    if(count <= 9){
                        this.cxcServerData[pageCount].data.push(item)
                    }
                    else{
                        count = 0;
                        pageCount = pageCount + 1;
                        this.cxcServerData[pageCount].data.push(item)
                    }
                    count = count + 1;
                });
            }
            this.$refs.graph2.resetPage();
            this.updateCXCData({page:1});
        },
        updateCXCData(data)
        {
            this.clearCXCData();
            this.cxcServerData[data.page].data.forEach(item => {
                this.cxcData.labels.push(item.client_name);
                this.cxcData.datasets[0].data.push(item.paid);
            });
            
        },
        clearCXCData()
        {
            
            this.cxcData.labels = [];
            this.cxcData.datasets[0].data = []
        },






       
        resetPage(){
            this.$refs.graph1.resetPage();
            this.$refs.graph2.resetPage();
            this.$refs.graph3.resetPage();
            this.$refs.graph4.resetPage();
        },
        
    }
}
</script>

<style lang="scss">
    .testtest{
        max-height: 70px;
        overflow: hidden;
        padding-bottom: 10px;
    }
</style>